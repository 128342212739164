import React from "react";

export default function Impressum() {
  return (
    <section className="about-section section" id="about">
      <div className="container">
        <h1>Impressum</h1>

        <p>
          Nico Backhaus
          <br />
          Nico Backhaus F&ouml;rderungen &amp; Finanzen
          <br />
          Handelsvertreter nach Paragraph 84 HGB.
          <br />
          Hellwigstra&szlig;e 11
          <br />
          99817 Eisenach
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: 015222685987
          <br />
          E-Mail: info@backhaus-immo.de
        </p>

        <h2>Aufsichtsbeh&ouml;rde</h2>
        <p>
          Stadtverwaltung Eisenach
          <br />
          Markt 2<br />
          99817 Eisenach
        </p>
        <p>
          <a
            href="http://info@eisenach.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://info@eisenach.de
          </a>
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h2>
          Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung
          (EU) 2022/265)
        </h2>
        <p>
          Unsere zentrale Kontaktstelle f&uuml;r Nutzer und Beh&ouml;rden nach
          Art. 11, 12 DSA erreichen Sie wie folgt:
        </p>
        <p>
          E-Mail: info@backhaus-immo.de
          <br />
          Telefon: 015222685987
        </p>
        <p>
          Die für den Kontakt zur Verf&uuml;gung stehenden Sprachen sind:
          Deutsch, Englisch, Deutsch, Englisch.
        </p>
        <p>
          Quelle:{" "}
          <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </div>
    </section>
  );
}
