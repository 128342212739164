import React, { Suspense } from 'react';
import Brands from '../components/Brands';
import Projects from '../components/Projects';
import Testimonial from '../components/Testimonial';
import Hero from '../components/Hero';
import About from '../components/About';
import Service from '../components/Service';
import HomePagdData from '../data/HomePagdData.json';
//import Experience from '../components/Experience';
const Contact = React.lazy(() => import('../components/Contact'));

export default function Home() {
  const {
    hero,
    socialBtns,
    brands,
    about,
    projects,
    service,
   // experience,
    testimonial,
    contact,
  } = HomePagdData;
  return (
    <>
      <Hero data={hero} socialData={socialBtns} />
      <Brands data={brands} />
      <About data={about} />
      <Projects data={projects} />
      <Service data={service} />
   {/*  <Experience data={experience} /> */ }
      <Testimonial data={testimonial} />
      <Suspense fallback={<div>Loading...</div>}>
        <Contact data={contact} socialData={socialBtns} />
      </Suspense>

    </>
  );
}
