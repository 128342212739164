import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
      <a className="m-0 container" href="/impressum">Impressum</a>
      <a className="m-0 container"  href="/datenschutz">Datenschutz</a>
        <p className="m-0 text-center container">© 2023 copyright all right reserved</p>
        <p className="m-0 text-center container">👨🏻‍💻 Developed by <a href='https://brundam.de/'>brundam.de</a></p>
      </div>
    </footer>
  );
}
